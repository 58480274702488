import React, { useCallback, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import { ThemeMode } from "store/user/userReducer";
import { styled, IconButtonProps, Tooltip } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import { useAppSelector } from "shared/utils/redux/hooks";
import { useDispatch } from "react-redux";
import { setCurrentThemeMode } from "store/user/userActions";

type ThemeSwitchProps = {
  showTooltip?: boolean;
};
export const ThemeSwitch = ({ showTooltip = false }: ThemeSwitchProps) => {
  const themeMode = useAppSelector((state) => state.user.themeMode);
  const dispatch = useDispatch();
  const handleThemeMenuClick = useCallback(() => {
    if (themeMode === "dark") {
      dispatch(setCurrentThemeMode("light"));
    } else {
      dispatch(setCurrentThemeMode("dark"));
    }
  }, [dispatch, themeMode]);

  const themeButton = useMemo(
    () => (
      <StyledIconButton themeMode={themeMode} onClick={handleThemeMenuClick}>
        {themeMode === "dark" ? (
          <DarkModeIcon sx={{ "& path": { color: "warning.main" } }} />
        ) : (
          <LightModeIcon />
        )}
      </StyledIconButton>
    ),
    [handleThemeMenuClick, themeMode]
  );
  return (
    <>
      {showTooltip ? (
        <Tooltip title={themeMode === "dark" ? "Dark" : "Light"}>
          {themeButton}
        </Tooltip>
      ) : (
        <>{themeButton}</>
      )}
    </>
  );
};

type StyledIconButtonType = IconButtonProps & {
  themeMode: ThemeMode;
};

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "docDirection" && prop !== "themeMode",
})<StyledIconButtonType>(({ theme, themeMode }) => ({
  cursor: "pointer",
  "& path": {
    color:
      themeMode === "dark"
        ? theme.palette.custom.sub1
        : theme.palette.custom.label,

    transition: theme.transitions.create([" color", "transform"], {
      duration: theme.transitions.duration.standard,
    }),
  },

  ml: 1,
  "&:hover": {
    "& path": {
      color: "#e76712",
    },
    transform: "scale(1.02)",
  },
}));
