import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { useRoutesForPublicAccess } from "./useRoutesForPublicAccess";
import { AppRoot } from "shared/appContainers";
import { ErrorBoundaryLayout } from "routes/ErrorBoundaryLayout";

export const useMainRouter = () => {
  const routesForPublicAccess = useRoutesForPublicAccess([]);
  // const mainRouter = createBrowserRouter([...routesForPublicAccess]);
  const mainRouter = createBrowserRouter([
    {
      element: (
        <ErrorBoundaryLayout>
          <AppRoot />
        </ErrorBoundaryLayout>
      ),
      children: [...routesForPublicAccess],
    },
  ]);
  return mainRouter;
};
