
import React from "react";
import { RouterProvider } from "react-router-dom";
import { useMainRouter } from "routes/hooks/useMainRouter";

function App() {
  const router = useMainRouter();

  return <RouterProvider router={router} />;
}

export default App;
