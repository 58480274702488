import {
  Box,
  Divider,
  Grid,
  Paper,
  PaperProps,
} from "@mui/material";
import React from "react";

export const ContainerPaper = ({
  children,
  headerInfo = undefined,
  mt = 0,
  ...props
}: ContainerPaperProps) => {
  return (
    <Paper
      square
      {...props}
      sx={{ height: "100%", pt: `${mt}px` }}
    >
      {headerInfo && (
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "2px 5px",
            }}
          >
          </Grid>

          <Divider sx={{ mb: "5px" }} />
        </Box>
      )}
      {children}
    </Paper>
  );
};

type ContainerPaperProps = PaperProps & {
  headerInfo?: {
    show: boolean;
    title: string;
    subheader?: string;
    icon: React.ReactNode;
  };
  children: React.ReactNode;
  mt?: number;
};
