import { Button, ButtonProps, Typography, styled } from "@mui/material";
import React from "react";

export const CustomButton = ({ label, icon, ...props }: CustomButtonProps) => {
  return (
    <StyledButton
      variant="contained"
      style={{
        borderRadius: "20px",
        padding: "20px",
      }}
      {...props}
      aria-disabled={props.disabled}
      endIcon={icon ?? null}
    >
      <Typography>{label}</Typography>
    </StyledButton>
  );
};

type CustomButtonProps = ButtonProps & {
  icon?: React.ReactNode;
  label?: React.ReactNode;
};

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  maxWidth: "300px",
  height: "30px",
  margin: "0px 5px",
  boxShadow: "none",
  textTransform: "none",
  fontSize: 12,
  padding: "0px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  borderColor: "#0063cc",
  "&:hover": {
    backgroundColor: theme.palette.custom.sub1,
    borderColor: theme.palette.custom.label,
  },
  "&:active": {
    backgroundColor: theme.palette.custom.sub1,
    borderColor: theme.palette.custom.label,
  },
}));
