import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { ContainerPaper } from "shared/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoSection from "./components/InfoSection";
import OpenCommunication from "./components/OpenCommunication";
import EmployeeDevelopment from "./components/EmployeeDevelopment";

const WorkPrinciplesPage = () => {
  
  const baseUrl = process.env.REACT_APP_BASE_URL!;

  const handleOnClick = useCallback(() => {
    const element = document.getElementById("section1");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <ContainerPaper
      mt={80}
      style={{
        backgroundImage: "url(images/smtlogo2.png)",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>{`MASSARI LTD - Portfolio`}</title>
        <link rel="canonical" href={`${baseUrl}/portfolio`} />
      </Helmet>
      <Box sx={{ mt: "20px",backgroundColor: "rgba(255, 255, 255,0.8)" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "160px",
            mb: "100px",
            backgroundColor: "custom.sub8",
            "& svg": {
              transition: "all 0.4s ease-out",
              position: "absolute",
              transform: "scale(1)",
              top: "120px",
              cursor: "pointer",
              pt: "0px",
              "&:hover": {
                top: "125px",
                transition: "all 0.4s ease-out",
                transform: "scale(1.3)",
              },
            },
            "& path": { color: "custom.sub1" },
            "& circle": { color: "custom.sub1" },
            position: "relative",
          }}
        >
          <Typography
            color="custom.label"
            sx={{ mb: 1 }}
            fontSize={"2rem"}
            fontWeight={"bold"}
          >
            Our Work Principles
          </Typography>
          <KeyboardArrowDownIcon onClick={handleOnClick} />
        </Box>
        <Box
          id="section1"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            color="custom.label"
            sx={{ mb: 1 }}
            fontSize="2rem"
            fontWeight="bold"
          >
            {"Social responsibility"}
          </Typography>
        </Box>
      </Box>
      <InfoSection />
      <OpenCommunication />
      <EmployeeDevelopment />
    </ContainerPaper>
  );
};

export default WorkPrinciplesPage;
