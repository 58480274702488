import React from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
 
import { MappedLinkInfo } from "types";
import { CustomLink } from "shared/components";

type MenuBarProps = {
  links: Array<MappedLinkInfo>;
};

export const MenuBar = ({
  links,
}: MenuBarProps) => {
  
  const { pathname } = useLocation();

  return (
    <Box
      className="link-container"
      sx={{
        display: "flex",
        justifyContent: "space-around",
        gap: 2,
        flexGrow: 4,
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "start", flexGrow: 1, gap: 2 }}
      >
        {links.map((link) =>
          link.protected ? (
            null
          ) : (
            <CustomLink
              key={link.id}
              to={link.path}
              isActive={pathname === link.path}
              icon={link.icon}
            >
              {link.label}
            </CustomLink>
          )
        )}
      </Box>
    </Box>
  );
};
