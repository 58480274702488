import React, { MouseEvent } from "react";
import { Box, Link as MUILink, Typography } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import { isEmpty } from "lodash";
import { useWindowScrollerYPosition } from "pages/AppLayout/components/hooks/useWindowScroll";

type CustomLinkProps = Omit<LinkProps, "onClick"> & {
  children: React.ReactNode;
  isActive?: boolean;
  icon?: React.ReactNode;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
};

const CustomLink = ({
  children,
  isActive = false,
  icon,
  onClick,
  ...props
}: CustomLinkProps) => {
  const yScroller = useWindowScrollerYPosition();
  const linkColor = yScroller > 100 ? "custom.label" : "custom.label";
  return (
    <MUILink
      component={Link}
      {...props}
      underline="none"
      onClick={(e) => {
        if (isEmpty(props.to)) {
          e.preventDefault();
        }
        onClick?.(e as React.MouseEvent<HTMLAnchorElement>);
      }}
      className="custom-link"
      sx={{
        display: "inline-block",
        fontWeight: isActive ? "bold" : "normal",
        textDecoration: "none",
        "& .MuiTypography-root": {
          margin: "0px 10px",
          transition: "color 0.2s ease-in",
        },
        "& path": {
          transition: "color 0.2s ease-in",
        },
        "& circle": {
          transition: "color 0.2s ease-in",
        },
        "&:hover": {
          "& .MuiTypography-root": {
            color: "custom.hover",
          },
          "& path": {
            color: "custom.hover",
          },
          "& circle": {
            color: "custom.hover",
          },
        },
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "start", justifyContent: "start" }}
      >
        {icon && (
          <Box
            sx={{
              color: isActive ? "#e76712" : linkColor,
              "& path": { color: isActive ? "#e76712" : linkColor },
              "& circle": {
                color: isActive ? "#e76712" : linkColor,
              },
            }}
          >
            {icon}
          </Box>
        )}
        <Typography
          component="span"
          color={isActive ? "#e76712" : linkColor}
          sx={{
            fontSize: 14,
            fontWeight: isActive ? "bold" : "normal",
            textShadow: "#fff 1px 1px 1px",
          }}
        >
          {children}
        </Typography>
      </Box>
    </MUILink>
  );
};
export { CustomLink };
