import { Theme, createTheme } from "@mui/material";
const fontFamily = [
  "Roboto",
  "Open Sans",
  "sans-serif",
  "Helvetica",
];

declare module "@mui/material/styles" {
  interface Palette {
    custom: {
      main: React.CSSProperties["color"];
      link1: React.CSSProperties["color"];
      label: React.CSSProperties["color"];
      sub1: React.CSSProperties["color"];
      sub2: React.CSSProperties["color"];
      sub3: React.CSSProperties["color"];
      sub4: React.CSSProperties["color"];
      sub8: React.CSSProperties["color"];
      sub9: React.CSSProperties["color"];
      hover: React.CSSProperties["color"];
    };
  }
  interface PaletteOptions {
    custom: {
      main: React.CSSProperties["color"];
      link1: React.CSSProperties["color"];
      label: React.CSSProperties["color"];
      sub1: React.CSSProperties["color"];
      sub2: React.CSSProperties["color"];
      sub3: React.CSSProperties["color"];
      sub4: React.CSSProperties["color"];
      sub8: React.CSSProperties["color"];
      sub9: React.CSSProperties["color"];
      hover: React.CSSProperties["color"];
    };
  }
  interface TypographyVariants {
    mainTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    mainTitle?: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    mainTitle: true;
  }
}

export const getTheme = ({
  themeMode,
}: {
  themeMode: "light" | "dark";
}): Theme => {
  const theme = createTheme({
    direction: "ltr",
    palette: {
      mode: themeMode,
      primary: {
        main: "#1976d2",
        dark: "#1565c0",
        light: "#42a5f5",
      },
      secondary: { main: "#9c27b0", dark: "#7b1fa2", light: "#ba68c8" },
      success: { main: "#2e7d32", dark: "#1b5e20", light: "#4caf50" },
      info: { main: "#0288d1", dark: "#01579b", light: "#03a9f4" },
      warning: { main: "#ed6c02", dark: "#e65100", light: "#ff9800" },
      error: { main: "#d32f2f", dark: "#c62828", light: "#ef5350" },
      custom: {
        main: themeMode === "dark" ? "#1976d2" : "#fff",
        link1: themeMode === "dark" ? "#1976d2" : "#6c6b6b",
        label: themeMode === "dark" ? "#1976d2" : "#272727",
        sub1: themeMode === "dark" ? "#A6262E" : "#1976d2",
        sub2: themeMode === "dark" ? "#1976d2" : "#aaa",
        sub3: themeMode === "dark" ? "#272727" : "#4e40ea",
        sub4: themeMode === "dark" ? "#272727" : "#fff", //main dark
        sub8: themeMode === "dark" ? "#5d5c5c" : "#F6F6F6",
        sub9: themeMode === "dark" ? "#5d5c5c" : "#BB9B64",
        hover: "#e76712",
      },
    },
    spacing: 4,
    typography: {
      fontFamily: fontFamily.join(","),
      fontSize: 12,
      mainTitle: {
        fontSize: "1.5rem",
        color: "blue",
      },
      body1: {
        fontWeight: 500,
      },
    },

    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: `2px 4px 8px ${
              themeMode === "dark" ? "#1976d2" : "#aaa"
            }, -1px -1px 2px ${themeMode === "dark" ? "#1976d2" : "#aaa"}`,
          },
        },
      },

      MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
          "@font-face": {
            fontFamily: fontFamily.join(","),
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: "local('Raleway'), local('Raleway-Regular'), url(${RalewayWoff2}) format('woff2')",
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
          },
        }),
      },
      MuiTypography: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& strong": {
              color:
                themeMode === "dark"
                  ? "#ff9800 !important"
                  : "#1976d2 !important",
            },
          }),
        },
        defaultProps: {
          variantMapping: {
            mainTitle: "h1",
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: "medium",
        },
        styleOverrides: {
          root: {
            // "& path": {
            //   color: themeMode === "dark" ? "#ff9800" : "#1976d2",
            // },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
          disableElevation: true,
          // disableRipple: true,
          size: "medium",
          color: themeMode === "dark" ? "warning" : "info",
        },
        styleOverrides: {
          root: {
            fontFamily: fontFamily.join(","),
            padding: "2px 5px",
            fontSize: "14px",
            fontWeight: 500,
            alignItems: "center",
            textTransform: "unset",
          },
        },
      },
    },
  });
  return theme;
};
