import { createAction } from "shared/utils/redux/reduxUtils";
import { viewUtilsActionTypes } from "./viewUtilsActionTypes";
import { BreadCrumbsIndex, DataGridIndex, Toast } from "./viewUtilsReducer";

export const setBreadCrumbsIndex = (index: BreadCrumbsIndex) =>
  createAction(viewUtilsActionTypes.SET_BREAD_CRUMBS_INDEX, index);

export const setDataGridIndex = (index: DataGridIndex) =>
  createAction(viewUtilsActionTypes.SET_DATA_GRID_INDEX, index);

export const setDrawerStatus = (show: boolean) =>
  createAction(viewUtilsActionTypes.SET_DRAWER_STATUS, show);
