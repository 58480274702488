import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ReactComponent as Logo } from "assets/images/ErrorSVG.svg";
import ReplayIcon from "@mui/icons-material/Replay";

export const ReactErrorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <ErrorBoundary
    fallbackRender={({ resetErrorBoundary, error }) => (
      <FallbackBox
        errorMessage={error.message}
        onClick={() => resetErrorBoundary()}
      />
    )}
  >
    {children}
  </ErrorBoundary>
);

const FallbackBox = ({
  errorMessage,
  onClick,
}: {
  errorMessage: string;
  onClick: () => void;
}) => {
  return (
    <div className="central-box" style={{ padding: "10px", marginTop:"100px" }}>
      <Logo style={{ width: `250px`, height: `250px` }} />
      <div>Try again</div>
      <button onClick={() => onClick()}>
        <ReplayIcon />
      </button>
    </div>
  );
};
