import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as Logo } from "assets/images/ErrorSVG.svg";
import { useGetUIThemeMode } from "shared/hooks";

export const ErrorLogo = ({ size = 50 }: ErrorLogoProps) => {
  const currentThemeMode = useGetUIThemeMode();
  return (
    <Box
      sx={{
        "& g": { fill: currentThemeMode === "dark" ? "#ed6c02" : "#000" },
        "& path": {
          color: currentThemeMode === "dark" ? "warning.main" : "#000",
        },
      }}
    >
      <Logo style={{ width: `${size}px`, height: `${size}px` }} />
    </Box>
  );
};

type ErrorLogoProps = {
  size?: number;
};
