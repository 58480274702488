import { List, styled } from "@mui/material";

const StyledList = styled(List)`
  padding: 5px;
  margin-bottom: 10px;
  width: 180px;
  a {
    text-decoration: none;
    color: #fff;
  }
`;

export { StyledList };
