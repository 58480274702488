import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

export const useAppLayoutHelpper = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));


  useEffect(() => {
    const handleOnScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 90 && isScrolling === false) setIsScrolling(true);

      if (scrollPosition < 90 && isScrolling === true) setIsScrolling(false);
    };
    window.addEventListener("scroll", handleOnScroll);
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [isScrolling]);

  return {
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen,
  };
};
