import React, { memo } from "react";
import Drawer from "@mui/material/Drawer";
import { IconButton, Box } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useLocation } from "react-router-dom";
import { MappedLinkInfo } from "types";
import { StyledList } from "./components";
import { useAppSelector } from "shared/utils/redux/hooks";
import { useDispatch } from "react-redux";
import { setDrawerStatus } from "store/viewUtils/viewUtilsActions";
import { Logo } from "assets/svgs/Logo";
import { ListLink } from "shared/components";

type CustomDrawerProps = {
  links: Array<MappedLinkInfo>;
  isMediumScreen: boolean;
};

const drawerWidth = "180px";

export const CustomDrawer = memo(
  ({
    links,
    isMediumScreen,
  }: CustomDrawerProps) => {
    const openDrawer = useAppSelector((state) => state.viewUtils.drawerStatus);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const drawerLinks: Array<MappedLinkInfo> = [
      ...links,
      { path: "", label: "", protected: false, id: "0", icon: null },
    ];
    

    return (
      <>
        {isMediumScreen ? (
          <>
            <IconButton
              aria-label="Open menu drawer"
              onClick={() => dispatch(setDrawerStatus(true))}
            >
              <MenuOutlinedIcon></MenuOutlinedIcon>
            </IconButton>

            <Drawer
              sx={{
                width: drawerWidth,
                "& .MuiListItemIcon-root": {
                  minWidth: "auto",
                  marginRight: "5px",
                },
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  backgroundColor: "custom.sub4",
                },
              }}
              SlideProps={{
                direction: "right",
              }}
              open={openDrawer}
              anchor="left"
              onClose={() => dispatch(setDrawerStatus(false))}
              PaperProps={{ sx: { backgroundColor: "custom.sub3" } }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Box sx={{ marginTop: "5px", color: "#fff" }}>
                  <Logo width={130} height={40} />
                </Box>
              </Box>
              <StyledList
                sx={{ width: "100%", maxWidth: 360, pl: 1 }}
                aria-labelledby="nested-list-subheader"
                disablePadding
              >
                {drawerLinks.map((link) => {
                  if (link.label !== "")
                    return link.protected ? (
                  null
                    ) : (
                      <ListLink
                        isActive={pathname === link.path}
                        key={link.id}
                        to={link.path}
                        onClick={() => dispatch(setDrawerStatus(false))}
                        label={link.label}
                        icon={link.icon}
                        disableGutters
                      />
                    );
                  return <div key={link.id}>&nbsp;</div>;
                })}

              </StyledList>
            </Drawer>
          </>
        ) : null}
      </>
    );
  }
);
CustomDrawer.displayName = "CustomDrawer";
