import React from "react";
import { Grid, Typography, styled } from "@mui/material";

const InfoSection = () => {
  
  return (
    <Grid container sx={{ py: "10px",backgroundColor: "rgba(255, 255, 255,0.8)" }} >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          p:"10px"
        }}
      >
        <Typography color="custom.sub1" fontSize="1.5rem">
          {`Social responsibility is about giving something back, and we do this with every project we take on A portion of each year's profits goes back to the charities. 
We believe that social responsibility and giving back to the local community should be a part of everything Massari does. 
`}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {<StyledImg src={"/images/about-us/about-us.jpg"} />}
      </Grid>
    </Grid>
  );
};

export default InfoSection;

const StyledImg = styled("img")`
  width: 400px;
  height: 200px;
  aspect-ratio: 1;
  transition: all 0.5s;
`;
