import React from "react";
import ThemedApp from "shared/appContainers/components/ThemedApp";
import { Outlet } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

const AppRoot = () => {
  return (
    <HelmetProvider>
      <ThemedApp>
        <Outlet />
      </ThemedApp>
    </HelmetProvider>
  );
};

export default AppRoot;
