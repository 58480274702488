import { viewUtilsActionTypes } from "./viewUtilsActionTypes";
import { AlertProps } from "@mui/material";

export type BreadCrumbsIndex = {
  id: string;
  path: string;
  value?: any;
};
export type DataGridIndex = {
  id: string;
  key: any;
};

export type Toast = {
  show: boolean;
  alertSeverity: AlertProps["severity"];
  label: string;
};

type viewUtilsState = {
  breadCrumbsIndexes: BreadCrumbsIndex[];
  dataGridIndexes: DataGridIndex[];
  drawerStatus: boolean;
};

interface viewUtilsAction {
  type: any;
  payload: BreadCrumbsIndex;
}

const initailState: viewUtilsState = {
  breadCrumbsIndexes: [{ id: "0", path: "" }],
  dataGridIndexes: [{ id: "0", key: "" }],
  drawerStatus: false,
};

export const viewUtilsReducer = (state = initailState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case viewUtilsActionTypes.SET_BREAD_CRUMBS_INDEX: {
      const isExist = state.breadCrumbsIndexes.find(
        (item) => item.id === payload.id
      );
      return {
        ...state,
        breadCrumbsIndexes: isExist
          ? (state.breadCrumbsIndexes || []).map((item) =>
              item.id === (payload as BreadCrumbsIndex).id
                ? (payload as BreadCrumbsIndex)
                : item
            )
          : [...state.breadCrumbsIndexes, payload as BreadCrumbsIndex],
      };
    }
    case viewUtilsActionTypes.SET_DATA_GRID_INDEX: {
      const isExist = state.dataGridIndexes.find(
        (item) => item.id === payload.id
      );
      return {
        ...state,
        dataGridIndexes: isExist
          ? (state.dataGridIndexes || []).map((item) =>
              item.id === (payload as DataGridIndex).id
                ? (payload as DataGridIndex)
                : item
            )
          : [...state.dataGridIndexes, payload as DataGridIndex],
      };
    }
    case viewUtilsActionTypes.SET_DRAWER_STATUS:
      return {
        ...state,
        drawerStatus: payload as boolean,
      };
    default:
      return state;
  }
};
