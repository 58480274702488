import React, { lazy, useMemo } from "react";
import NotFoundPage from "pages/NotFound/NotFoundPage";
import { Navigate, RouteObject } from "react-router-dom";
import { SuspensedPageView } from "routes/SuspensedPageView";
import AppLayout from "pages/AppLayout/AppLayout";
import WorkPrinciplesPage from "pages/workPrinciples/WorkPrinciplesPage";


const PortfolioPage = lazy(() => import("pages/Portfolio/PortfolioPage"));
const OurServicesPage = lazy(() => import("pages/OurServices/OurServicesPage"));
const HomePage = lazy(() => import("pages/Home/HomePage"));
const AboutUsPage = lazy(() => import("pages/AboutUs/AboutUsPage"));

export const useRoutesForPublicAccess = (
  otherRouters: RouteObject[]
): RouteObject[] =>
  useMemo(
    () => [
      {
        path: "/",
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: (
              <SuspensedPageView>
                <HomePage />
              </SuspensedPageView>
            ),
          },
          {
            path: "/our-services",
            element: (
              <SuspensedPageView>
                <OurServicesPage />
              </SuspensedPageView>
            ),
          },
          {
            path: "/about-us",
            element: (
              <SuspensedPageView>
                <AboutUsPage />
              </SuspensedPageView>
            ),
          },
          {
            path: "/portfolio",
            element: (
              <SuspensedPageView>
                <PortfolioPage />
              </SuspensedPageView>
            ),
          },
          {
            path: "/work-principles",
            element: (
              <SuspensedPageView>
                <WorkPrinciplesPage />
              </SuspensedPageView>
            ),
          },
          {
            path: "*",
            element: <Navigate replace to="/404" />,
          },
          {
            path: "/404",
            element: <NotFoundPage />,
          },
          ...otherRouters,
        ],
      },
    ],
    [otherRouters]
  );
