import {
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
} from "@mui/material";
import React, {useMemo } from "react";
import { getTheme } from "theme/getTheme";
import { useGetUIThemeMode } from "shared/hooks";

const ThemedApp = ({ children }: ThemedAppProps) => {
  const themeMode = useGetUIThemeMode();

  const theme = useMemo(() => getTheme({ themeMode }), [themeMode]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </MuiThemeProvider>
  );
};

type ThemedAppProps = { children: React.ReactNode };

export default ThemedApp;
