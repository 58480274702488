import React from "react";
import { Link, Paper, Typography } from "@mui/material";
import { Link as RouterDomLink } from "react-router-dom";

import { ColoredDevider, ContainerPaper } from "shared/components";
import PublicOffIcon from '@mui/icons-material/PublicOff';
const NotFoundPage = () => {
  

  return (
    <ContainerPaper mt={80}>
      <Paper className="central-box" sx={{ py: 5 }}>
        {/* <img src={imgSrc} alt="" style={{ width: "250px", height: "200px" }} /> */}
        <Typography color="custom.label" fontSize={"4rem"} sx={{ mb: "20px" }}>
        404
      </Typography>
      <Typography color="custom.label" fontSize={"3rem"} sx={{ mb: "20px" }}>
        Page not found
      </Typography>
      <PublicOffIcon  style={{marginBottom:"10px",width:"60px",height:"60px" }}/>
        <ColoredDevider />
        <Link
          component={RouterDomLink}
          to="/"
          sx={{ textDecoration: "none", color: "custom.sub1" }}
        >
          Home
        </Link>
      </Paper>
    </ContainerPaper>
  );
};
export default NotFoundPage;
