import { Box, Typography, styled } from "@mui/material";
import React from "react";


const OpenCommunication= () => {

  return (
    <Box
      id="our-strategy"
      sx={{
        p:"50px 10px",
        backgroundColor: "custom.sub8",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        color="custom.sub1"
        sx={{ fontWeight: "bold" }}
        fontSize={"2rem"}
      >
        {`Open Communication`}
      </Typography>
      <Typography
        color="custom.label"
        sx={{ fontWeight: "bold" }}
        fontSize={"1.6rem"}
      >
        {`From the very beginning, Massari has committed itself fully to the ideals of transparency and accountability Because of this, we have built trust with our customers and we have attracted and retained the best employees There has to be frank and open dialogue - both internally and with the outside world We listen, and we encourage people to speak up.`}
      </Typography>
    </Box>
  );
};

const StyledImg = styled("img")`
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
  height: 600px;
  aspect-ratio: 1;
`;

const LabelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  borderRadius: "15px",
  bottom: "15px",
  left: "0px",
  width: "200px",
  height: "200px",
  backgroundColor: theme.palette.custom.sub9,
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));

export default OpenCommunication;
