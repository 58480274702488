import React from "react";
import { Box, styled } from "@mui/material";
import { GridLoader } from "react-spinners";

const LoadingSpinner = ({
  floatingOver,
  size = 20,
}: {
  floatingOver?: boolean;
  size?: number;
}) => {
  const floatingOverStyle: React.CSSProperties = floatingOver
    ? {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: 1001,
      }
    : {};
  if (floatingOver) {
    return (
      <>
        <Container />
        <Box
          sx={{
            ...floatingOverStyle,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <GridLoader color="rgba(54, 126, 214, 1)" size={size} />
        </Box>
      </>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <GridLoader color="rgba(54, 126, 214, 1)" size={size} />
    </Box>
  );
};

export { LoadingSpinner };

const Container = styled(Box)({
  position: "absolute",
  top: "0px",
  bottom: "0px",
  left: "0px",
  right: "0px",
  backgroundColor: "#eee",
  opacity: "0.5",
  zIndex: 1000,
});
