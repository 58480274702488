import { Paper, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  ColoredDevider,
  CustomButton,
  LoadingSpinner,
} from "shared/components";
import { ErrorLogo } from "assets/svgs/ErrorLogo";

import ReplayIcon from "@mui/icons-material/Replay";

export const ReactQueryErrorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <ErrorBoundary
    fallbackRender={({ resetErrorBoundary, error }) => (
      <FallbackBox
        // errorMessage="The service is not available now !!"
        errorMessage={error.message}
        onClick={() => resetErrorBoundary()}
      />
    )}
  >
    <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
    {/* <ReactQueryDevtools /> */}
  </ErrorBoundary>
);

const FallbackBox = ({
  errorMessage,
  onClick,
}: {
  errorMessage: string;
  onClick: () => void;
}) => {
  
  return (
    <Paper className="central-box" sx={{ py: 5, marginTop: "100px" }}>
      <ErrorLogo size={200} />
      <Typography color="custom.label">
        Request error
      </Typography>
      <ColoredDevider />

      <CustomButton
        onClick={onClick}
        icon={<ReplayIcon />}
        label={"Try again"}
      />
      <CustomButton
        onClick={() => window?.location?.reload()}
        icon={<ReplayIcon />}
        label={"Reload page"}
      />
    </Paper>
  );
};
