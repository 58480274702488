import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as Logo2 } from "assets/images/vehicles-management-system-logo.svg";
import { useGetUIThemeMode } from "shared/hooks";
export const Logo = ({ width = 102, height=58 }: LogoProps) => {
  const currentThemeMode = useGetUIThemeMode();
  return (
    <Box
      sx={{
        "& g": { fill: currentThemeMode === "dark" ? "#ed6c02" : "#000" },
        "& path": {
          color: currentThemeMode === "dark" ? "warning.main" : "#000",
        },
      }}
    >
      <img src="/images/smtlogo.png" alt="Massari LTD for inspection logo" style={{ width: `${width}px`, height: `${height}px` }} />
    </Box>
  );
};

type LogoProps = {
  width?: number;
  height?: number;
};
