import { useEffect, useState } from "react";

export const useWindowScrollerYPosition = () => {
  const [scrollerYPosition, setScrollerYPosition] = useState(0);
  const handleOnScroll = () => {
    const newScrollYPosition = window.scrollY;
    setScrollerYPosition(newScrollYPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll);
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, []);
  return scrollerYPosition;
};
