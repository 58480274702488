
import { userActionTypes } from "./userActionTypes";

export type ThemeMode="light" | "dark"
type UserState = {
  themeMode: ThemeMode;
};


const initailState: UserState = {
  themeMode: "light",
};
export const userReducer = (state = initailState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case userActionTypes.SET_CURRENT_THEME_MODE:
      return {
        ...state,
        themeMode: payload as ThemeMode,
      };
    default:
      return state;
  }
};
