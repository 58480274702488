import { Box, Typography, styled } from "@mui/material";
import React from "react";

const EmployeeDevelopment = () => {
  return (
    <Box
      id="our-strategy"
      sx={{
        p: "20px 10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255,0.8)"
      }}
    >
      <Typography
        color="custom.label"
        sx={{ fontWeight: "bold" }}
        fontSize={"2rem"}
      >
        {`Employee Development & Positive Work Environment: `}
      </Typography>
      <Typography
        color="custom.sub1"
        fontSize={"1.5rem"}
      >
        <ul>
          <li>
            {
              "Massari strives to provide excellent customer service as well as high quality products. To ensure these goals are met, Massari equips its employees with the necessary knowledge through formal trainings or hands-on day-to-day experiences. "
            }
          </li>
          <br />
          <li>
            {
              "Massari is committed to providing its employees with all necessary guidance, support and ample opportunities to work with pride and confidence and for career advancement. "
            }
          </li>
          <br />
          <li>
            {
              "Massari is an equal opportunity employer and believes in strength through diversity."
            }
          </li>
          <br />
          <li>
            {
              "Massari offers much more than a job, it offers the chance to grow."
            }
          </li>
          <br />
        </ul>
      </Typography>
    </Box>
  );
};

const StyledImg = styled("img")`
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
  height: 600px;
  aspect-ratio: 1;
`;

const LabelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  borderRadius: "15px",
  bottom: "15px",
  left: "0px",
  width: "200px",
  height: "200px",
  backgroundColor: theme.palette.custom.sub9,
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
}));

export default EmployeeDevelopment;
